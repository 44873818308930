import { CONFIG } from "./config";

export const checkSession = async (): Promise<void> => {
    try {
        const sessionData = await CONFIG.fetchAndStoreSession();
        if (!sessionData) {
            throw new Error("Session initialization failed.");
        }
    } catch (error) {
        console.error("Error during session initialization:", error);
        CONFIG.redirectToError();
    }
};
