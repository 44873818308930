import { useState, useEffect, useRef, ChangeEvent, FormEvent } from 'react';
import { Form, Image, InputGroup } from 'react-bootstrap';
import { Button } from '@stillfront/stillfront-component-library';
import emailIcon from '../../assets/images/email.png';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CONFIG } from '../../utils/config';
import './EmailPopUp.scss';

const EmailPopUp = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>('');
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
    const [verificationFailed, setVerificationFailed] = useState<boolean>(false);
    const [errorMessageKey, setErrorMessageKey] = useState<string>('');
    const [getTokenFailed, setTokenFailed] = useState<boolean>(false);
    const { setEmailValue } = useUser();
    const emailInputRef = useRef<HTMLInputElement>(null);

    const validateEmail = (inputEmail: string): void => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(inputEmail));
    };

    useEffect(() => {
        const initializeSessionAndTrackEvent = async () => {
            try {
                await CONFIG.fetchAndStoreSession();
                setTokenFailed(false);

                CONFIG.sendTrackingEvent({
                    stepId: 'ENTER_EMAIL_DIALOG',
                    message: 'User reached the enter email for verification step',
                });
            } catch (error) {
                setTokenFailed(true);
                console.error('Error fetching CSRF token:', error);
            }

            if (emailInputRef.current) {
                emailInputRef.current.focus();
            }
        };

        initializeSessionAndTrackEvent();
    }, []);

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setTokenFailed(false);
        const inputEmail = e.target.value;
        setEmail(inputEmail);
        validateEmail(inputEmail);
    };

    const handleVerifyEmail = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setEmailValue(email);

        try {
            await CONFIG.apiCall('/api/v1/auth/verify-email', 'POST', { email });
            setVerificationFailed(false);
            navigate('/verify-code', { state: { email } });
        } catch (error: any) {
            console.error('Error:', error);
            const errorMessageKey = error.status
                ? `errors.${error.status}`
                : 'errors.default';
            setVerificationFailed(true);
            setErrorMessageKey(errorMessageKey);
        }
    };

    return (
        <>
            <Form validated={isValidEmail} className="auth-container" onSubmit={handleVerifyEmail}>
                <p className="label-text">{t('verificationCodeSent')}</p>
                <Form.Group className="form-content">
                    <div className="input-wrapper">
                        <InputGroup className="w-auto">
                            <InputGroup.Text className="input-group-text">
                                <Image src={emailIcon} alt="Email Icon" className="email-icon" />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                ref={emailInputRef}
                                type="email"
                                aria-label="email"
                                name="email"
                                autoComplete="email"
                                className="email-input"
                                placeholder={t('enterEmail')}
                                value={email}
                                onChange={handleEmailChange}
                                isValid={isValidEmail}
                                isInvalid={!isValidEmail && email !== ''}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('invalidEmailError')}
                            </Form.Control.Feedback>
                        </InputGroup>
                        {getTokenFailed && <p className="token-error-message">{t('tokenErrorMessage')}</p>}
                        {verificationFailed && <p className="error-message" aria-label="error-message">{t(errorMessageKey)}</p>}
                    </div>
                    <Button type="submit" variant="contained" aria-label="email-submit" className="submit-btn">
                        {t('proceed')}
                    </Button>
                </Form.Group>
            </Form>
        </>
    );
};

export default EmailPopUp;
