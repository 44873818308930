import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { checkSession } from './utils/session';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

checkSession().then(() => {
    const root = ReactDOM.createRoot(document.getElementById('root')!);
    document.documentElement.className = 'light-theme';
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
});
